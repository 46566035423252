export const types = {

    libroDigitalAsistenciaScreen:'[LibroDigital-Asistencia] Screen Principal',
    libroDigitalAsistenciaRegistroScreen:'[LibroDigital-Asistencia] Screen Registro',
    libroDigitalAsistenciaInformeScreen:'[LibroDigital-Asistencia] Screen Informe',

    libroDigitalTablaAsistencias: "[LibroDigital] tabla asistencias",
    libroDigitalTablaAsistenciaEstudiantes: "[LibroDigital] tabla asistencia estudiante",
    libroDigitalInformeDetalleAsistenciaEstudiante: "[LibroDigital] Informe Asistencia detalle",

    // anotaciones 
    libroDigitalAnotacionScreen:"[LibroDigital] Anotacion estudiante screen",

    //Router
    libroDigitalTablaAniosEscolares:'[LibroDigital] tabla anios escolares',
    libroDigitalTablaCursos:'[LibroDigital] tabla cursos',
    libroDigitalTablaEstudiantes:'[LibroDigital] tabla estudiante',
    libroDigitalTablaAsignaturasCurso:'[LibroDigital] tabla asignatura curso',
    libroDigitalTablaAsignaturasCursoSegundo:'[LibroDigital] tabla asignatura curso segundo',
    libroDigitalTablaAsignaturasCursoFinal:'[LibroDigital] tabla asignatura curso final',
    libroDigitalTablaPeriodos:'[LibroDigital] tabla periodos',
    libroDigitalTablaAsignaturas:'[LibroDigital] tabla asignaturas',
    libroDigitalTablaClasesAsignatura:'[LibroDigital] tabla clases asignatura',
    libroDigitalFormClaseAsignaturaNueva:'[LibroDigital] form clase nueva asignatura',
    libroDigitalFormClaseAsignaturaActualizar:'[LibroDigital] form clase actualizar asignaturas',
    libroDigitalTablaCalificacionesAsignatura:'[libroDigital] tabla calificaciones asignatura',
    libroDigitalTablaTalleres:'[LibroDigital] tabla talleres',
    libroDigitalTablaClasesTaller:'[LibroDigital] tabla clases taller',
    libroDigitalFormClaseTallerNueva:'[LibroDigital] form clase taller nueva',
    libroDigitalFormClaseTallerActualizar:'[LibroDigital] Form clase taller actualizar',
    libroDigitalTablaCalificacionesTaller:'[libroDigital] calificaciones taller',


    // CRUD 
    //establecimiento 
    crearEstablecimientoOk:'[establecimiento] crear ok',
    crearEstablecimientoError:'[establecimiento] crear error',
    obtenerEstablecimientosOk:'[establecimientos] obtener ok',
    obtenerEstablecimientosError:'[establecimientos] obtener error',
    actualizarEstablecimientoOk:'[establecimiento] actualizar ok',
    actualizarEstablecimientoError:'[establecimiento] actualizar error',
    activarEstablecimiento:'[establecimiento] activo',

    // categoria noticia
    crearCategoriaNoticiaOk:'[categoria-noticia] crear ok',
    crearCategoriaNoticiaError:'[categoria-noticia] crear error',
    obtenerCategoriasNoticiaOk:'[categoria-noticia] obtener ok',
    obtenerCategoriasNoticiaError:'[categoria-noticia] obtener error',
    actualizarCategoriaNoticiaOk:'[categoria-noticia] actualizar ok',
    actualizarCategoriaNoticiaError:'[categoria-noticia] actualizar error',
    activarCategoriaNoticia:'[categoria-noticia] activar ok',
    
    // noticia
    crearNoticiaOk:'[noticia] crear ok',
    crearNoticiaError:'[noticia] crear error',
    obtenerNoticiasOk:'[noticia] obtener ok',
    obtenerNoticiasError:'[noticia] obtener error',
    actualizarNoticiaOk:'[noticia] actualizar ok',
    actualizarNoticiaError:'[noticia] actualizar error',
    activarNoticia:'[noticia] activar ok',
    
    // informacion establecimiento
    crearEstablecimientoInformacionOk:'[informacion-establecimiento] crear ok',
    crearEstablecimientoInformacionError:'[informacion-establecimiento] crear error',
    obtenerEstablecimientoInformacionOk:'[informacion-establecimiento] obtener ok',
    obtenerEstablecimientoInformacionError:'[informacion-establecimiento] obtener error',
    actualizarEstablecimientoInformacionOk:'[informacion-establecimiento] actualizar ok',
    actualizarEstablecimientoInformacionError:'[informacion-establecimiento] actualizar error',
    activarEstablecimientoInformacion:'[informacion-establecimiento] activar ok',
    limpiarEstablecimientoInformacion:'[informacion-establecimiento] limpiar ok',


    // usuario
    crearUsuarioOk:'[usuario] crear ok',
    crearUsuarioError:'[usuario] crear error',
    obtenerUsuariosOk:'[usuario] obtener ok',
    obtenerUsuariosError:'[usuario] obtener error',
    obtenerDocentesOk:'[usuario] obtener docentes ok',
    obtenerDocentesError:'[usuario] obtener docentes error',
    actualizarUsuarioOk:'[usuario] actualizar ok',
    actualizarUsuarioError:'[usuario] actualizar error',
    activarUsuario:'[usuario] activar ok',
    
    //credencial usuario
    crearCredencialUsuarioOk:'[credencial-usuario] crear ok',
    crearCredencialUsuarioError:'[credencial-usuario] crear error',
    obtenerCredencialesUsuarioOk:'[credencial-usuario] obtener ok',
    obtenerCredencialesUsuarioError:'[credencial-usuario] obtener error',
    actualizarCredencialUsuarioOk:'[credencial-usuario] actualizar ok',
    actualizarCredencialUsuarioError:'[credencial-usuario] actualizar error',
    activarCredencialUsuario:'[credencial-usuario] activar ok',
    limpiarCredencialesUsuario:'[credencial-usuario] limpiar ok',
    activarCredencialUsuarioLogin:'[credencial-usuario] activar login ok',
    
    // informacion usuario
    crearUsuarioInformacionOk:'[informacion-usuario] crear ok',
    crearUsuarioInformacionError:'[informacion-usuario] crear error',
    obtenerUsuarioInformacionOk:'[informacion-usuario] obtener ok',
    obtenerUsuarioInformacionError:'[informacion-usuario] obtener error',
    actualizarUsuarioInformacionOk:'[informacion-usuario] actualizar ok',
    actualizarUsuarioInformacionError:'[informacion-usuario] actualizar error',
    activarUsuarioInformacion:'[informacion-usuario] activar ok',
    limpiarUsuarioInformacion:'[informacion-usuario] limpiar ok',

    // estudiante
    crearEstudianteOk:'[estudiante] crear ok',
    crearEstudianteError:'[estudiante] crear error',
    obtenerEstudiantesOk:'[estudiante] obtener ok',
    obtenerEstudiantesError:'[estudiante] obtener error',
    actualizarEstudianteOk:'[estudiante] actualizar ok',
    actualizarEstudianteError:'[estudiante] actualizar error',
    activarEstudiante:'[estudiante] activar ok',

    // estudiante credencial
    crearCredencialEstudianteOk:'[credencial-estudiante] crear ok',
    crearCredencialEstudianteError:'[credencial-estudiante] crear error',
    obtenerCredencialesEstudianteOk:'[credencial-estudiante] obtener ok',
    obtenerCredencialesEstudianteError:'[credencial-estudiante] obtener error',
    actualizarCredencialEstudianteOk:'[credencial-estudiante] actualizar ok',
    actualizarCredencialEstudianteError:'[credencial-estudiante] actualizar error',
    activarCredencialEstudiante:'[credencial-estudiante] activar ok',
    limpiarCredencialesEstudiante:'[credencial-estudiante] limpiar ok',
    

    // libro digital crud
    // anio escolar
    crearAnioEscolarOk:'[AnioEscolar] crear ok',
    crearAnioEscolarError:'[AnioEscolar] crear error',
    obtenerAniosEscolaresOk:'[AnioEscolar] obtener ok',
    obtenerAniosEscolaresError:'[AnioEscolar] obtener error',
    actualizarAnioEscolarOk:'[AnioEscolar] actualizar ok',
    actualizarAnioEscolarError:'[AnioEscolar] actualizar error',
    activarAnioEscolar:'[AnioEscolar] activar ok',
    limpiarAniosEscolares:'[AnioEscolar] limpiar ok',
    
    // periodo escolar
    crearPeriodoEscolarOk:'[PeriodoEscolar] crear ok',
    crearPeriodoEscolarError:'[PeriodoEscolar] crear error',
    obtenerPeriodosEscolaresOk:'[PeriodoEscolar] obtener ok',
    obtenerPeriodosEscolaresError:'[PeriodoEscolar] obtener error',
    actualizarPeriodoEscolarOk:'[PeriodoEscolar] actualizar ok',
    actualizarPeriodoEscolarError:'[PeriodoEscolar] actualizar error',
    activarPeriodoEscolar:'[PeriodoEscolar] activar ok',
    limpiarPeriodosEscolares:'[PeriodoEscolar] limpiar ok',
    
    // Cursos
    crearCursoOk:'[Curso] crear ok',
    crearCursoError:'[Curso] crear error',
    obtenerCursosOk:'[Curso] obtener ok',
    obtenerCursosError:'[Curso] obtener error',
    actualizarCursoOk:'[Curso] actualizar ok',
    actualizarCursoError:'[Curso] actualizar error',
    activarCurso:'[Curso] activar ok',
    limpiarCursos:'[Curso] limpiar ok',
    
    // asistencia
    crearAsistenciaOk:'[Asistencia] crear ok',
    crearAsistenciaError:'[Asistencia] crear error',
    obtenerAsistenciasOk:'[Asistencia] obtener ok',
    obtenerAsistenciasError:'[Asistencia] obtener error',
    actualizarAsistenciaOk:'[Asistencia] actualizar ok',
    actualizarAsistenciaError:'[Asistencia] actualizar error',
    activarAsistencia:'[Asistencia] activar ok',
    limpiarAsistencias:'[Asistencia] limpiar ok',
    // asistencia
    crearAsistenciaEstudianteOk:'[Asistencia-Estudiante] crear ok',
    crearAsistenciaEstudianteError:'[Asistencia-Estudiante] crear error',
    obtenerAsistenciaEstudiantesOk:'[Asistencia-Estudiante] obtener ok',
    obtenerAsistenciaEstudiantesError:'[Asistencia-Estudiante] obtener error',
    actualizarAsistenciaEstudianteOk:'[Asistencia-Estudiante] actualizar ok',
    actualizarAsistenciaEstudianteError:'[Asistencia-Estudiante] actualizar error',
    activarAsistenciaEstudiante:'[Asistencia-Estudiante] activar ok',
    limpiarAsistenciaEstudiantes:'[Asistencia-Estudiante] limpiar ok',
    
    // asignaturas
    crearAsignaturaOk:'[Asignatura] crear ok',
    crearAsignaturaError:'[Asignatura] crear error',
    obtenerAsignaturasOk:'[Asignatura] obtener ok',
    obtenerAsignaturasError:'[Asignatura] obtener error',
    actualizarAsignaturaOk:'[Asignatura] actualizar ok',
    actualizarAsignaturaError:'[Asignatura] actualizar error',
    activarAsignatura:'[Asignatura] activar ok',
    limpiarAsignaturas:'[Asignatura] limpiar ok',

    // clases asignatura
    crearClaseAsignaturaOk:'[Clase-Asignatura] crear ok',
    crearClaseAsignaturaError:'[Clase-Asignatura] crear error',
    obtenerClasesAsignaturaOk:'[Clase-Asignatura] obtener ok',
    obtenerClasesAsignaturaError:'[Clase-Asignatura] obtener error',
    actualizarClaseAsignaturaOk:'[Clase-Asignatura] actualizar ok',
    actualizarClaseAsignaturaError:'[Clase-Asignatura] actualizar error',
    activarClaseAsignatura:'[Clase-Asignatura] activar ok',
    limpiarClasesAsignatura:'[Clase-Asignatura] limpiar ok',
    // contenido clase asignatura
    crearContenidoClaseAsignaturaOk:'[Contenido-Clase-Asignatura] crear ok',
    crearContenidoClaseAsignaturaError:'[Contenido-Clase-Asignatura] crear error',
    obtenerContenidoClaseAsignaturaOk:'[Contenido-Clase-Asignatura] obtener ok',
    obtenerContenidoClaseAsignaturaError:'[Contenido-Clase-Asignatura] obtener error',
    actualizarContenidoClaseAsignaturaOk:'[Contenido-Clase-Asignatura] actualizar ok',
    actualizarContenidoClaseAsignaturaError:'[Contenido-Clase-Asignatura] actualizar error',
    activarContenidoClaseAsignatura:'[Contenido-Clase-Asignatura] activar ok',
    limpiarContenidoClaseAsignatura:'[Contenido-Clase-Asignatura] limpiar ok',

    // clases asignatura
    crearCalificacionAsignaturaOk:'[Calificacion-Asignatura] crear ok',
    crearCalificacionAsignaturaError:'[Calificacion-Asignatura] crear error',
    obtenerCalificacionesAsignaturaOk:'[Calificacion-Asignatura] obtener ok',
    obtenerCalificacionesAsignaturaError:'[Calificacion-Asignatura] obtener error',
    actualizarCalificacionAsignaturaOk:'[Calificacion-Asignatura] actualizar ok',
    actualizarCalificacionAsignaturaError:'[Calificacion-Asignatura] actualizar error',
    activarCalificacionAsignatura:'[Calificacion-Asignatura] activar ok',
    limpiarCalificacionesAsignatura:'[Calificacion-Asignatura] limpiar ok',
    // clases asignatura
    crearCalificacionDetalleAsignaturaOk:'[calificacion-detalle-Asignatura] crear ok',
    crearCalificacionDetalleAsignaturaError:'[calificacion-detalle-Asignatura] crear error',
    obtenerCalificacionesDetalleAsignaturaOk:'[calificacion-detalle-Asignatura] obtener ok',
    obtenerCalificacionesDetalleAsignaturaError:'[calificacion-detalle-Asignatura] obtener error',
    actualizarCalificacionDetalleAsignaturaOk:'[calificacion-detalle-Asignatura] actualizar ok',
    actualizarCalificacionDetalleAsignaturaError:'[calificacion-detalle-Asignatura] actualizar error',
    activarCalificacionDetalleAsignatura:'[calificacion-detalle-Asignatura] activar ok',
    limpiarCalificacionesDetalleAsignatura:'[calificacion-detalle-Asignatura] limpiar ok',
    
    // talleres
    crearTallerOk:'[Taller] crear ok',
    crearTallerError:'[Taller] crear error',
    obtenerTalleresOk:'[Taller] obtener ok',
    obtenerTalleresError:'[Taller] obtener error',
    actualizarTallerOk:'[Taller] actualizar ok',
    actualizarTallerError:'[Taller] actualizar error',
    activarTaller:'[Taller] activar ok',
    limpiarTalleres:'[Taller] limpiar ok',


    // clases taller
    crearClaseTallerOk:'[Clase-Taller] crear ok',
    crearClaseTallerError:'[Clase-Taller] crear error',
    obtenerClasesTallerOk:'[Clase-Taller] obtener ok',
    obtenerClasesTallerError:'[Clase-Taller] obtener error',
    actualizarClaseTallerOk:'[Clase-Taller] actualizar ok',
    actualizarClaseTallerError:'[Clase-Taller] actualizar error',
    activarClaseTaller:'[Clase-Taller] activar ok',
    limpiarClasesTaller:'[Clase-Taller] limpiar ok',
    // contenido clase taller
    crearContenidoClaseTallerOk:'[Contenido-Clase-Taller] crear ok',
    crearContenidoClaseTallerError:'[Contenido-Clase-Taller] crear error',
    obtenerContenidoClaseTallerOk:'[Contenido-Clase-Taller] obtener ok',
    obtenerContenidoClaseTallerError:'[Contenido-Clase-Taller] obtener error',
    actualizarContenidoClaseTallerOk:'[Contenido-Clase-Taller] actualizar ok',
    actualizarContenidoClaseTallerError:'[Contenido-Clase-Taller] actualizar error',
    activarContenidoClaseTaller:'[Contenido-Clase-Taller] activar ok',
    limpiarContenidoClaseTaller:'[Contenido-Clase-Taller] limpiar ok',

    // calificacion taller
    crearCalificacionTallerOk:'[Calificacion-Taller] crear ok',
    crearCalificacionTallerError:'[Calificacion-Taller] crear error',
    obtenerCalificacionesTallerOk:'[Calificacion-Taller] obtener ok',
    obtenerCalificacionesTallerError:'[Calificacion-Taller] obtener error',
    actualizarCalificacionTallerOk:'[Calificacion-Taller] actualizar ok',
    actualizarCalificacionTallerError:'[Calificacion-Taller] actualizar error',
    activarCalificacionTaller:'[Calificacion-Taller] activar ok',
    limpiarCalificacionesTaller:'[Calificacion-Taller] limpiar ok',
    
    
    // calificacion detalle taller
    crearCalificacionDetalleTallerOk:'[Calificacion-Detalle-Taller] crear ok',
    crearCalificacionDetalleTallerError:'[Calificacion-Detalle-Taller] crear error',
    obtenerCalificacionDetallesTallerOk:'[Calificacion-Detalle-Taller] obtener ok',
    obtenerCalificacionDetallesTallerError:'[Calificacion-Detalle-Taller] obtener error',
    actualizarCalificacionDetalleTallerOk:'[Calificacion-Detalle-Taller] actualizar ok',
    actualizarCalificacionDetalleTallerError:'[Calificacion-Detalle-Taller] actualizar error',
    activarCalificacionDetalleTaller:'[Calificacion-Detalle-Taller] activar ok',
    limpiarCalificacionDetallesTaller:'[Calificacion-Detalle-Taller] limpiar ok',

    // matriculas
    crearMatriculaOk:'[Matricula] crear ok',
    crearMatriculaError:'[Matricula] crear error',
    obtenerMatriculasOk:'[Matricula] obtener ok',
    obtenerMatriculasError:'[Matricula] obtener error',
    actualizarMatriculaOk:'[Matricula] actualizar ok',
    actualizarMatriculaError:'[Matricula] actualizar error',
    activarMatricula:'[Matricula] activar ok',
    limpiarMatriculas:'[Matricula] limpiar ok',
    
    // anotaciones
    crearAnotacionOk:'[Anotacion] crear ok',
    crearAnotacionError:'[Anotacion] crear error',
    obtenerAnotacionesOk:'[Anotacion] obtener ok',
    obtenerAnotacionesError:'[Anotacion] obtener error',
    actualizarAnotacionOk:'[Anotacion] actualizar ok',
    actualizarAnotacionError:'[Anotacion] actualizar error',
    activarAnotacion:'[Anotacion] activar ok',
    limpiarAnotaciones:'[Anotacion] limpiar ok',
    

    // ********************** ARCHIVOS **********************
    startUploadingOk: '[File] upload ok',
    startUploadingError: '[File] upload error',
    clearUploadFile: '[File] clear file',

    // ********************** Login **********************
    authStartLogin: '[auth] Start login',
    authCheckingFinish: '[auth] checking finish',
    authLogin: '[auth] authLogin',
    authLogout: '[auth] Logout',


};